import {
  getCitiesClearRequest,
  getCitiesTypesClearRequest
} from "@/helpers/api/cities";
import { getOrganizationsClearRequest } from "@/helpers/api/organizations";
import { getObjectsClearRequest } from "@/helpers/api/objects";
import { getKindergartensClearRequest } from "@/helpers/api/kindergartens";
import { getControllersClearRequest } from "@/helpers/api/controllers";
import { getCardsClearRequest } from "@/helpers/api/cards";
import {
  getAccountsClearRequest,
  getAccountsRequest
} from "@/helpers/api/accounts";
import {
  getUsersKindergartensClearRequest,
  // getKindergartensUserRequest,
  getUsersClearRequest,
  getUsersObjectsClearRequest
} from "@/helpers/api/user";
import { getServiceTasksRequest } from "@/helpers/api/serviceTasks";
import { getMessagesClearRequest } from "@/helpers/api/messages";
import { getKindergartensChildrenGroupsClearRequest } from "@/helpers/api/childrenGroup";
import { getkindergartensChildrenRequest } from "@/helpers/api/Children/Children";
import { getAccessGroupsClearRequest } from "@/helpers/api/access-group";
import { getControllerFirmwaresRequest } from "@/helpers/api/controller-firmwares";
import { getServiceGroupsClearRequest } from "@/helpers/api/service-groups";
import {
  getKindergartensPassageLogsRequest,
  getPassageLogsClearRequest,
  getPassageLogsObjectRequest
} from "@/helpers/api/PassageLog";

import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import { getPromocodeClearRequest } from "@/helpers/api/promocode";
import phoneFilter from "@/helpers/filters/phoneFilter";
import { getAccessesRequest } from "@/helpers/api/Access";
import {
  getKindergartensPaymentsRequest,
  getPaymentsObjectRequest
} from "@/helpers/api/payments";
import { getReviewRequest } from "@/helpers/api/review";
import { getIdentificatorUserRole } from "../Constants/roles";
import store from "@/store";

export const inputType = {
  search: 1,
  dataPicker: 2,
  dataPickerRange: 3
};

const diller = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name} ${el.full_org_name} ${el.TIN}`
      }));
    },
    name: "Название, ИНН",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "diller"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные дилеры",
    userRole: []
  }
];

const organizationsCompany = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name} ${el.full_org_name}, ${el.address}`
      }));
    },
    name: "Название, адрес",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "acquiring_provider",
    function: async () => {
      return [
        {
          value: "no",
          text: "Отсутствует"
        },

        {
          value: "sberbank",
          text: "Сбербанк"
        }
      ];
    },
    name: "Тип оплаты",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные компании",
    userRole: []
  }
];

const organizations = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: allQuery.diller ? ["default", "kindergarten"] : "default",
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}, ${el.full_org_name} ${el.TIN}`
      }));
    },
    name: "Название, ИНН ",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "organization"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные компании",
    userRole: []
  }
];

const objects = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, types: "object", search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}, ${el.address}`
      }));
    },
    name: "Название, адрес",
    userRole: [70, 60, 50, 40, 30]
  },
  {
    inputType: inputType.search,
    type: "city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "object"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: [70]
  },

  {
    inputType: inputType.search,
    type: "service_group",
    function: async (param, allQuery = {}) => {
      return (
        await getServiceGroupsClearRequest({
          query: {
            ...allQuery,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Группа объектов",
    userRole: [40, 50]
  },
  {
    inputType: inputType.search,
    type: "service_organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: "service",
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Обслуживающая организация",
    userRole: [30, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "service_group__specialist",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: {
            ...allQuery,
            "technicians-of-service": allQuery.service_organization,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.last_name ? el.last_name : ""} ${
          el.first_name ? el.first_name : ""
        } ${el.middle_name ? el.middle_name : ""}`
      }));
    },
    name: "Техник",
    userRole: [50]
  },
  {
    inputType: inputType.search,
    type: "organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "default", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60, 50]
  },
  {
    inputType: inputType.search,
    type: "organization__diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70, 50]
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные объекты",
    userRole: [70, 60, 50, 40, 30]
  }
];

const kindergartens = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getKindergartensClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}, ${el.full_org_name} ${el.TIN}, ${el.address}`
      }));
    },
    name: "Название, ИНН, адрес",
    userRole: [60, 70]
  },
  {
    inputType: inputType.search,
    type: "city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "kindergarten"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "owned_objects__service_organization",
    function: async param => {
      return (
        await getOrganizationsClearRequest({
          query: { types: "service", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Обслуживающая организация",
    userRole: [60, 70]
  },
  {
    inputType: inputType.search,
    type: "diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные детские сады",
    userRole: [60, 70]
  }
];

const kindergartensDealer = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getKindergartensClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}, ${el.full_org_name} , ${el.address}`
      }));
    },
    name: "Название, адрес",
    userRole: [60, 70]
  },
  {
    inputType: inputType.search,
    type: "owned_objects__service_organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "service", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Обслуживающая организация",
    userRole: [60, 70]
  },
  {
    inputType: inputType.search,
    type: "organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "default", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60]
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные детские сады",
    userRole: [60, 70]
  }
];

const controllers = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getControllersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}, ${el.id} `
      }));
    },
    name: "Название, ID, MAC",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "type",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ];
    },
    name: "Тип контроллера",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "primary_firmware",
    function: async (param, allQuery = {}) => {
      return (
        await getControllerFirmwaresRequest({
          query: {
            ...allQuery,
            search: param,
            offset: null,
            chip: "ESP",
            ordering: "-id"
          },
          id: localStorage.getItem("KindergartenObjectId")
            ? localStorage.getItem("KindergartenObjectId")
            : allQuery.object
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.chip || ""} ${el.version || ""}` || ""
      }));
    },
    name: "ESP",
    userRole: [30, 40, 50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "board_version_esp",
    function: async (param, allQuery = {}) => {
      return (
        await getControllerFirmwaresRequest({
          query: { ...allQuery, search: param, offset: null, chip: "ESP" },
          id: localStorage.getItem("KindergartenObjectId")
            ? localStorage.getItem("KindergartenObjectId")
            : allQuery.object
        })
      ).data.results
        .map(el => ({
          value: el.board_version ? el.board_version : "-1",
          text: el.board_version ? el.board_version : "-"
        }))
        .sort((a, b) => {
          return b.value - a.value;
        });
    },
    name: "Версия чипа ESP",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "secondary_firmware",
    function: async (param, allQuery = {}) => {
      return (
        await getControllerFirmwaresRequest({
          query: {
            ...allQuery,
            search: param,
            offset: null,
            chip: "NRF",
            ordering: "-id"
          },
          id: localStorage.getItem("KindergartenObjectId")
            ? localStorage.getItem("KindergartenObjectId")
            : allQuery.object
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.chip || ""} ${el.version || ""}` || ""
      }));
    },
    name: "NRF",
    userRole: [30, 40, 50, 60, 70]
  },

  {
    inputType: inputType.search,
    type: "board_version_nrf",
    function: async (param, allQuery = {}) => {
      return (
        await getControllerFirmwaresRequest({
          query: { ...allQuery, search: param, ordering: "-id", chip: "NRF" },
          id: localStorage.getItem("KindergartenObjectId")
            ? localStorage.getItem("KindergartenObjectId")
            : allQuery.object
        })
      ).data.results
        .map(el => ({
          value: el.board_version ? el.board_version : "-1",
          text: el.board_version ? el.board_version : "-"
        }))
        .sort((a, b) => {
          return b.value - a.value;
        });
    },
    name: "Версия чипа NRF",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_active",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: true,
          text: "Активирован"
        },
        {
          value: false,
          text: "Не активирован"
        }
      ];
    },
    name: "Статус",
    userRole: [30, 40, 50, 60, 70]
  },

  {
    inputType: inputType.search,
    type: "status",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: "connected",
          text: "В сети"
        },
        {
          value: "disconnected",
          text: "Не в сети"
        },
        {
          value: "not-configured",
          text: "Не настроен"
        }
      ];
    },
    name: "Доступ в сеть",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Объект",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object__organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60, 50]
  },
  {
    inputType: inputType.search,
    type: "object__organization__diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70, 50]
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные контроллеры",
    userRole: []
  }
];

const access = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.user,
        text:
          `${el.last_name ? el.last_name : ""} ${
            el.first_name ? el.first_name : ""
          } ${el.middle_name ? el.middle_name : ""}` +
          ", " +
          phoneFilter.execute(el?.phone_number)
      }));
    },
    name: "ФИО, телефон",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_active",
    function: async () => {
      return [
        {
          value: "true",
          text: "Включен"
        },
        {
          value: "false",
          text: "Выключен"
        }
      ];
    },
    name: "Статус",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "access_type",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          text: "Приложение",
          value: "app"
        },
        {
          text: "Карта",
          value: "card"
        }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "access_by",
    function: async () => {
      return [
        {
          text: "Бесплатный период",
          value: "trial"
        },
        {
          text: "Гостевой доступ",
          value: "guest"
        },
        {
          text: "Оплаченный доступ",
          value: "paid"
        },
        {
          text: "Неоплачиваемый доступ",
          value: "unpaid"
        },
        {
          text: "Активированный доступ",
          value: "activated"
        }
      ];
    },
    name: "Вид доступа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_active",
    function: async () => {
      return [
        {
          value: "48",
          text: "48"
        },
        {
          value: "12",
          text: "12"
        },
        {
          value: "3",
          text: "3"
        }
      ];
    },
    name: "Период, мес",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "group",
    function: async (param, allQuery = {}) => {
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "group__object",
    function: async () => {
      return (
        await getAccessesRequest({
          query: {}
        })
      ).data.results.map(el => ({
        value: el.object_details.id,
        text: el.object_details.name
      }));
    },
    name: "Объект",
    userRole: []
  }
];

const codes = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.user,
        text:
          `${el.last_name ? el.last_name : ""} ${
            el.first_name ? el.first_name : ""
          } ${el.middle_name ? el.middle_name : ""}` +
          ", " +
          phoneFilter.execute(el?.phone_number)
      }));
    },
    name: "ФИО, телефон",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "reg_status",
    function: async () => {
      return [
        {
          value: "password-changing",
          text: "Изменение пароля"
        },
        {
          value: "new-phone-not-validated",
          text: "Изменение телефона. Телефон не проверен"
        },
        {
          value: "password-needed",
          text: "Пароль не введен"
        },
        {
          value: "phone-not-validated",
          text: "Телефон не проверен"
        },
        {
          value: "delete-user-account",
          text: "Удаление учетной записи"
        }
      ];
    },
    name: "Причина",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "send_status",
    function: async () => {
      return [
        {
          value: "sent",
          text: "Отправлено"
        },
        {
          value: "no-sent",
          text: "Не отправлено"
        }
      ];
    },
    name: "Статус",
    userRole: []
  }
];

const review = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getReviewRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: phoneFilter.execute(el.created_by.phone_number)
      }));
    },
    name: "Телефон",
    userRole: [70]
  }
];

const cards = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getCardsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.code}`
      }));
    },
    name: "Номер карты",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "status",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: "issued",
          text: "Выдана"
        },
        {
          value: "access-finish",
          text: "Использована"
        },
        {
          value: "expired",
          text: " Просрочена"
        },
        {
          value: "lost",
          text: "Утеряна"
        },

        {
          value: "not-activated",
          text: "Не активирована"
        },
        {
          value: "deactivated",
          text: "Деактивирована"
        }
      ];
    },
    name: "Статус",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "with-payment",
    function: async () => {
      return [
        {
          value: "true",
          text: "Есть"
        },
        {
          value: "false",
          text: "Нет"
        }
      ];
    },
    name: "Статус оплаты",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object__organization",
    // eslint-disable-next-line no-unused-vars
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: "kindergarten",
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Детский сад",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "object__organization__diller",
    // eslint-disable-next-line no-unused-vars
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            types: ["diller", "oppen"],
            search: param,
            offset: null,
            diller: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [50, 60, 70]
  }
];

const users = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: phoneFilter.execute(el.phone_number)
      }));
    },
    name: "Телефон",
    userRole: [30, 31, 50, 60, 70]
  },

  // {
  //   inputType: inputType.search,
  //   type: "search",
  //   function: async (param, allQuery = {}) => {
  //     return (
  //       await getUsersKindergartensClearRequest({
  //         id: localStorage.getItem("organization") != 1
  //           ? localStorage.getItem("organization")
  //           : localStorage.getItem("KindergartenId"),
  //         query: { ...allQuery, search: param, offset: null }
  //       })
  //     ).data.results.map(el => ({
  //       value: el.id,
  //       text: phoneFilter.execute(el.phone_number)
  //     }));
  //   },
  //   name: "Телефон ДС",
  //   userRole: [30, 31, 50, 60, 70]
  // },

  {
    inputType: inputType.search, // 124
    type: "is_phone_confirmed",
    // eslint-disable-next-line no-unused-vars
    function: async value => {
      return [
        {
          text: `${dictionariesHelper.accountsStatus.userAuth.true}`,
          value: true
        },
        {
          text: `${dictionariesHelper.accountsStatus.userAuth.false}`,
          value: false
        }
      ];
    },
    name: "Регистрация",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search, // 124
    type: "is_active",
    // eslint-disable-next-line no-unused-vars
    function: async param => {
      return [
        {
          text: `${dictionariesHelper.accountsStatus.userStatus.true}`,
          value: true
        },
        {
          text: `${dictionariesHelper.accountsStatus.userStatus.false}`,
          value: false
        }
      ];
    },
    name: "Блокировка",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "groups",
    // eslint-disable-next-line no-unused-vars
    function: async (param, allQuery = {}) => {
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: [31]
  }
];

const usersKindergarten = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      let results = (
        await getUsersKindergartensClearRequest({
          id: getIdentificatorUserRole(),
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results;
      let list = new Set();
      results.forEach(el => {
        list.add({
          value: el.id,
          text: phoneFilter.execute(
            el?.phone_number +
              " " +
              el.additional_fields[0].value +
              " " +
              el.additional_fields[1].value
          )
        });
      });
      return Array.from(list);
    },
    name: "Телефон, пользователь, комментарий",
    userRole: [30, 31, 50, 60, 70]
  },
  {
    inputType: inputType.search, // 124
    type: "is_phone_confirmed",
    // eslint-disable-next-line no-unused-vars
    function: async value => {
      return [
        {
          text: `${dictionariesHelper.accountsStatus.userAuth.true}`,
          value: true
        },
        {
          text: `${dictionariesHelper.accountsStatus.userAuth.false}`,
          value: false
        }
      ];
    },
    name: "Регистрация",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search, // 124
    type: "is_active",
    // eslint-disable-next-line no-unused-vars
    function: async param => {
      return [
        {
          text: `${dictionariesHelper.accountsStatus.userStatus.true}`,
          value: true
        },
        {
          text: `${dictionariesHelper.accountsStatus.userStatus.false}`,
          value: false
        }
      ];
    },
    name: "Блокировка",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "groups",
    // eslint-disable-next-line no-unused-vars
    function: async (param, allQuery = {}) => {
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: [31]
  }
];
const objectUsers = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      let results = (
        await getUsersObjectsClearRequest({
          id: allQuery.object || localStorage.getItem("ObjectId"),
          query: { ...allQuery, offset: null }
        })
      ).data.results;
      let list = new Set();
      results.forEach(el => {
        list.add({
          value: el.id,
          text: phoneFilter.execute(
            el?.phone_number +
              " " +
              el?.additional_fields[0].value +
              " " +
              el?.additional_fields[1].value +
              " " +
              el?.additional_fields[2].value +
              " " +
              el?.additional_fields[3].value
          )
        });
      });
      return Array.from(list);
    },
    name: "Телефон, пользователь, дом/корпус, квартира/офис, номер авто",
    userRole: [30, 31, 50, 60, 70]
  },
  {
    inputType: inputType.search, // 124
    type: "is_phone_confirmed",
    // eslint-disable-next-line no-unused-vars
    function: async value => {
      return [
        {
          text: `${dictionariesHelper.accountsStatus.userAuth.true}`,
          value: true
        },
        {
          text: `${dictionariesHelper.accountsStatus.userAuth.false}`,
          value: false
        }
      ];
    },
    name: "Регистрация",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search, // 124
    type: "is_active",
    // eslint-disable-next-line no-unused-vars
    function: async param => {
      return [
        {
          text: `${dictionariesHelper.accountsStatus.userStatus.true}`,
          value: true
        },
        {
          text: `${dictionariesHelper.accountsStatus.userStatus.false}`,
          value: false
        }
      ];
    },
    name: "Блокировка",
    userRole: [50, 60, 70]
  }
];

const accounts = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getAccountsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.number }));
    },
    name: "Номер л/с",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "activated",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: "true",
          text: "Активирован"
        },
        {
          value: "false",
          text: "Не активирован"
        }
      ];
    },
    name: "Статус",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "object",
    function: async () => {
      return (
        await getAccountsRequest({
          query: {}
        })
      ).data.results.map(el => ({ value: el.object, text: el.object_name }));
    },
    name: "Объект",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object__organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "default", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60]
  },
  {
    inputType: inputType.search,
    type: "object__organization__diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  }
];

const groups = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Название",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Объект",
    userRole: [30, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "default", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60]
  },
  {
    inputType: inputType.search,
    type: "organization__diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "object__city",
    function: async param => {
      return (
        await getCitiesClearRequest({ search: param, offset: null })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: [70]
  }
];
const payments = [
  {
    inputType: inputType.search,
    type: "status__in",
    function: async () => {
      return [
        { text: "Завершен", value: "completed,awa-card" },
        { text: "Отменен", value: "reversed" },
        { text: "Возвращен", value: "refunded" }
      ];
    },
    name: "Статус платежа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "access-type",
    function: async () => {
      return [
        { text: "Приложение", value: "app" },
        { text: "Карта", value: "card" }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "paid-from",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата начала периода",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "paid-until",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата окончания периода",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "acquiring_entity__acquiring_provider",
    function: async () => {
      return [
        { text: "СПБ", value: "sbp" },
        { text: "Сбербанк", value: "sberbank" }
      ];
    },
    name: "Эквайринг",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "refunded_after",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Возврат с",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "refunded_before",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Возврат по",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.user,
        text: phoneFilter.execute(el?.phone_number)
      }));
    },
    name: "Телефон",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "duration",
    function: async () => {
      return [
        { text: "3", value: 3 },
        { text: "12", value: 12 },
        { text: "24", value: 24 },
        { text: "36", value: 36 },
        { text: "48", value: 48 },
        { text: "60 ", value: 60 }
      ];
    },
    name: "Срок действия",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "access__group",
    // eslint-disable-next-line no-unused-vars
    function: async (param, allQuery = {}) => {
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object-type",
    function: async () => {
      return [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ];
    },
    name: "Тип объекта",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "object",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Объект",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "default", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60]
  },
  {
    inputType: inputType.search,
    type: "diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            search: param,
            diller: null,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  }
];

const paymentsObject = [
  {
    inputType: inputType.search,
    type: "status__in",
    function: async () => {
      return [
        { text: "Завершен", value: "completed,awa-card" },
        { text: "Отменен", value: "reversed" },
        { text: "Возвращен", value: "refunded" }
      ];
    },
    name: "Статус платежа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "access-type",
    function: async () => {
      return [
        { text: "Приложение", value: "app" },
        { text: "Карта", value: "card" }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "paid-from",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата начала периода",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "paid-until",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата окончания периода",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "acquiring_entity__acquiring_provider",
    function: async () => {
      return [
        { text: "СПБ", value: "sbp" },
        { text: "Сбербанк", value: "sberbank" }
      ];
    },
    name: "Эквайринг",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "refunded_after",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Возврат с",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "refunded_before",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Возврат по",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "search",
    objType: "generall",

    function: async (param, allQuery = {}) => {
      return (
        await getUsersObjectsClearRequest({
          id: allQuery.object,
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.user,
        text: phoneFilter.execute(el?.phone_number)
      }));
    },
    name: "Телефон",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "search",
    objType: "kindergarten",

    function: async (param, allQuery = {}) => {
      let results = (
        await getKindergartensPaymentsRequest({
          id: allQuery.kindergarten,
          query: { ...allQuery, offset: null }
        })
      ).data.results;
      let list = new Set();
      results.forEach(el => {
        list.add({
          value: el.id,
          text: phoneFilter.execute(
            el?.user_phone +
              " " +
              el?.additional_fields[0]?.value +
              " " +
              el?.additional_fields[1]?.value
          )
        });
      });
      return Array.from(list);
    },
    name: "Телефон, пользователь, комментарий",
    userRole: [30, 31, 50, 60, 70]
  },

  {
    inputType: inputType.search,
    type: "search",
    objType: "object",
    function: async (param, allQuery = {}) => {
      let results = (
        await getPaymentsObjectRequest({
          id: allQuery.object,
          query: { ...allQuery, offset: null }
        })
      ).data.results;
      let list = new Set();
      results.forEach(el => {
        list.add({
          value: el.id,
          text: phoneFilter.execute(
            el?.user_phone +
              " " +
              el?.additional_fields[0]?.value +
              " " +
              el?.additional_fields[1]?.value +
              " " +
              el?.additional_fields[2]?.value +
              " " +
              el?.additional_fields[3]?.value
          )
        });
      });
      return Array.from(list);
    },
    name: "Телефон, пользователь, дом/корпус, квартира/офис, номер авто",
    userRole: [30, 31, 50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "duration",
    function: async () => {
      return [
        { text: "3", value: 3 },
        { text: "12", value: 12 },
        { text: "24", value: 24 },
        { text: "36", value: 36 },
        { text: "48", value: 48 },
        { text: "60 ", value: 60 }
      ];
    },
    name: "Срок действия",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "access__group",
    function: async (param, allQuery = {}) => {
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "object-type",
    function: async () => {
      return [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ];
    },
    name: "Тип объекта",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "object",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Объект",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "organization",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "default", search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [70, 60]
  },
  {
    inputType: inputType.search,
    type: "diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            search: param,
            diller: null,
            offset: null,
            types: ["diller", "oppen"]
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  }
];

const service = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, types: "service", search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name} ${el.full_org_name} ${el.TIN}`
      }));
    },
    name: "Название, ИНН",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "service"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_deleted",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Удаленные организации",
    userRole: []
  }
];

const serviceTask = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getServiceTasksRequest({
          query: { ...allQuery, types: "service", search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text:
          el.title +
          " " +
          el.object_details.address +
          " " +
          el.specialist_details.full_name
      }));
    },
    name: "ФИО, тема, адрес",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "priority",
    function: async () => {
      return [
        {
          value: 1,
          text: "Высокий"
        },
        {
          value: 2,
          text: "Обычный"
        }
      ];
    },
    name: "Приоритет",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "status",
    function: async () => {
      return [
        {
          value: "closed",
          text: "Закрыта"
        },
        {
          value: "in-process",
          text: "В работе"
        },
        {
          value: "finished",
          text: "Завершена"
        },
        {
          value: "repeated",
          text: "Повторная"
        },
        {
          value: "dismissed",
          text: "Отклонена"
        }
      ];
    },
    name: "Статус",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "task_type",
    function: async () => {
      return [
        {
          value: "add",
          text: "Добавление контроллера"
        },
        {
          value: "repair",
          text: "Ремонт"
        }
      ];
    },
    name: "Тип заявки",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "controller__object",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Объект",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "init_type",
    function: async () => {
      return [
        {
          value: "system",
          text: "Система"
        },
        {
          value: "admin",
          text: "Администратор"
        }
      ];
    },
    name: "Инициатор",
    userRole: []
  }
];

const message = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getMessagesClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: `${el.subject}` }));
    },
    name: "Тема",
    userRole: [20, 21, 30, 31, 40, 50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "by_city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "message"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "by_diller",
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: {
            ...allQuery,
            types: ["diller", "oppen"],
            diller: null,
            search: param,
            offset: null
          }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Дилер",
    userRole: [70]
  },
  {
    inputType: inputType.search,
    type: "by_ownership",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        { text: "ИП", value: "ИП" },
        { text: "ПАО", value: "ПАО" },
        { text: "НАО", value: "НАО" },
        { text: "ООО", value: "ООО" }
      ];
    },
    name: "Тип компании",
    userRole: [20, 30, 50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "by_organization",
    // eslint-disable-next-line no-unused-vars
    function: async (param, allQuery = {}) => {
      return (
        await getOrganizationsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Компания",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "by_object_type",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ];
    },
    name: "Тип объекта",
    userRole: [50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "by_object",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Объект",
    userRole: [30, 40, 50, 60, 70]
  },
  {
    inputType: inputType.dataPicker,
    type: "by-day",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата и время",
    userRole: [20, 21, 30, 31, 40, 50, 60, 70]
  }
];
const groupChildren = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getKindergartensChildrenGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null },
          id: getIdentificatorUserRole()
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}`
      }));
    },
    name: "Название детской группы",
    userRole: [31, 70]
  },
  {
    inputType: inputType.search,
    type: "number",
    function: async (param, allQuery = {}) => {
      return (
        await getKindergartensChildrenGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null },
          id: getIdentificatorUserRole()
        })
      ).data.results.map(el => ({
        value: el.number,
        text: `${el.number}`
      }));
    },
    name: "Номер группы",
    userRole: [31, 70]
  }
];

const Children = [
  {
    inputType: inputType.search,
    type: "name__icontains",
    function: async (param, allQuery = {}) => {
      return (
        await getkindergartensChildrenRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.name,
        text: `${el.name ? el.name : ""}`
      }));
    },
    name: "Воспитанник",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "number",
    function: async (param, allQuery = {}) => {
      return (
        await getkindergartensChildrenRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.number,
        text: `${el.number ? el.number : ""}`
      }));
    },
    name: "Табельный номер",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "children_group",
    function: async (param, allQuery = {}) => {
      return (
        await getKindergartensChildrenGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null },
          id: allQuery.children_group__group__organization
        })
      ).data.results.map(el => ({
        value: el.id,
        text: el.name
      }));
    },
    name: "Группа",
    userRole: []
  },
  // {
  //   inputType: inputType.search,
  //   type: "search",
  //   function: async (param, allQuery = {}) => {
  //     let results = (
  //       await getkindergartensChildrenRequest({
  //         query: { ...allQuery, offset: null },
  //         children_group: allQuery.children_group
  //       })
  //     ).data.results;
  //
  //     // Фильтрация результатов по полю id
  //     let filteredResults = results.filter(item => item.id);
  //
  //     let parents_list = new Set();
  //     filteredResults.forEach(item => {
  //       item.parents_details.forEach(el => {
  //         parents_list.add({
  //           value: el,
  //           text: phoneFilter.execute(
  //             el?.phone_number +
  //             " " +
  //             el.additional_fields[0]?.value +
  //             " " +
  //             el.additional_fields[1]?.value
  //           )
  //         });
  //       });
  //     });
  //     return Array.from(parents_list);
  //   },
  //   name: "Телефон, пользователь, комментарий",
  //   userRole: []
  // }
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      let results = (
        await getkindergartensChildrenRequest({
          query: { ...allQuery, offset: null },
          children_group: allQuery.children_group
        })
      ).data.results;

      let parents_list = new Set();

      results.forEach(item => {
        const itemId = item.id; // Сохраняем значение id в переменной itemId

        item.parents_details.forEach(el => {
          parents_list.add({
            value: itemId, // Используем сохраненное значение id
            text: phoneFilter.execute(el?.phone_number)
          });
        });
      });

      return Array.from(parents_list);
    },
    name: "Телефон",
    userRole: []
  }
];

const passLogCards = [
  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы от",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы до",
    userRole: []
  }
];

const passLog = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      let response = await getPassageLogsClearRequest({
        query: {
          ...allQuery,
          search: param,
          offset: null,
          "optional-fields": ["controller_details", "user_details"]
        }
      });
      let results = response.data.results;
      let list = new Set();
      let userId = null;

      results.forEach(el => {
        if (!userId && el?.data?.user_id) {
          userId = el.data?.user_id;
        }
      });

      if (!param) {
        store.commit("SET_SELECTED_USER_ID", null);
      } else if (userId) {
        store.commit("SET_SELECTED_USER_ID", userId);
      }

      results.map(el => {
        list.add({
          value: el.id,
          text: phoneFilter.execute(
            el?.data.user_phone_number + " " + ` ${el.data.controller_name}`
          )
        });
      });
      return Array.from(list);
    },
    name: "Телефон, контроллер",
    userRole: [30, 31, 50, 60, 70]
  },

  {
    inputType: inputType.search,
    type: "controller__type",
    function: async () => {
      return [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ];
    },
    name: "Тип контроллера",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата начала периода",
    userRole: [5]
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      console.log(param);
      return [{ value: param, text: param }];
    },
    name: "Дата окончания периода",
    userRole: [5]
  },
  {
    inputType: inputType.search,
    type: "by_card",
    function: async () => {
      return [
        {
          value: "true",
          text: "Карта"
        },
        {
          value: "false",
          text: "Приложение"
        }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "data__access_group_id",
    function: async (param, allQuery = {}) => {
      const userId = store.getters.selectedUserId;
      allQuery = {
        organization: allQuery.controller__object__organization,
        object: allQuery.controller__object
      };
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null, user: userId }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы от",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы до",
    userRole: []
  }
];

const kindergatensPassLog = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      let response = await getKindergartensPassageLogsRequest({
        id: getIdentificatorUserRole(),
        query: {
          ...allQuery,
          search: param,
          offset: null,
          "optional-fields": ["user_details", "controller_details"]
        }
      });
      let results = response.data.results;
      let list = new Set();
      let userId = null;
      results.forEach(el => {
        if (!userId && el.user_details?.id) {
          userId = el.user_details?.id;
        }
      });

      if (!param) {
        store.commit("SET_SELECTED_USER_ID", null);
      } else if (userId) {
        store.commit("SET_SELECTED_USER_ID", userId);
      }

      results.map(el => {
        list.add({
          value: el.id,
          text:
            phoneFilter.execute(el.user_details?.phone_number) +
            " " +
            ` ${el.controller_details?.name}`
        });
      });
      return [...list];
    },
    name: "Телефон,контроллер",
    userRole: []
  },

  {
    inputType: inputType.search,
    type: "controller__type",
    function: async () => {
      return [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ];
    },
    name: "Тип контроллера",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      console.log(param);

      return [{ value: param, text: param }];
    },
    name: "Дата начала периода",
    userRole: [5]
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      console.log(param);

      return [{ value: param, text: param }];
    },
    name: "Дата окончания периода",
    userRole: [5]
  },
  {
    inputType: inputType.search,
    type: "by_card",
    function: async () => {
      return [
        {
          value: "true",
          text: "Карта"
        },
        {
          value: "false",
          text: "Приложение"
        }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "data__access_group_id",
    function: async (param, allQuery = {}) => {
      const userId = store.getters.selectedUserId;
      allQuery = {
        organization: allQuery.controller__object__organization,
        object: allQuery.controller__object
      };
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null, user: userId }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы от",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы до",
    userRole: []
  }
];

const passLogObject = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      let results = (
        await getPassageLogsObjectRequest({
          id: allQuery.controller__object,
          query: {
            ...allQuery,
            search: param,
            offset: null,
            "optional-fields": ["controller_details", "user_details"]
          }
        })
      ).data.results;
      let list = new Set();
      results.forEach(el => {
        list.add({
          value: el.id,
          text:
            phoneFilter.execute(el?.user_details?.phone_number) +
            " " +
            ` ${el.controller_details?.name}`
        });
      });
      return Array.from(list);
    },
    name: "Телефон, контроллер",
    userRole: [30, 31, 50, 60, 70]
  },
  {
    inputType: inputType.search,
    type: "controller__type",
    function: async () => {
      return [
        {
          value: "door",
          text: "Дверь"
        },
        {
          value: "wicket",
          text: "Калитка"
        },
        {
          value: "wing-gate",
          text: "Ворота распашные"
        },
        {
          value: "sliding-gate",
          text: "Ворота откатные"
        },
        {
          value: "lift-gate",
          text: "Шлагбаум"
        },
        {
          value: "porch-door",
          text: "Дверь подъездная"
        },
        {
          value: "inner-door",
          text: "Дверь внутренняя"
        },
        {
          value: "apartment-room",
          text: "Дверь квартирная"
        },
        {
          value: "lift",
          text: "Лифт"
        },
        {
          value: "mailbox",
          text: "Почтовый ящик"
        },
        {
          value: "garage-gate",
          text: "Гаражные ворота"
        },
        {
          value: "turnstile",
          text: "Турникет"
        }
      ];
    },
    name: "Тип контроллера",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата начала периода",
    userRole: [5]
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата окончания периода",
    userRole: [5]
  },
  {
    inputType: inputType.search,
    type: "by_card",
    function: async () => {
      return [
        {
          value: "true",
          text: "Карта"
        },
        {
          value: "false",
          text: "Приложение"
        }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "data__access_group_id",
    function: async (param, allQuery = {}) => {
      allQuery = {
        organization: allQuery.controller__object__organization,
        object: allQuery.controller__object
      };
      return (
        await getAccessGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({ value: el.id, text: el.title }));
    },
    name: "Группа пользователей",
    userRole: []
  },

  {
    inputType: inputType.dataPicker,
    type: "moment__gte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы от",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "moment__lte",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Проходы до",
    userRole: []
  }
];

const staff = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.user,
        text:
          `${el.last_name ? el.last_name : ""} ${
            el.first_name ? el.first_name : ""
          } ${el.middle_name ? el.middle_name : ""}` +
          ", " +
          phoneFilter.execute(el?.phone_number)
      }));
    },
    name: "ФИО, телефон",
    userRole: []
  }
];

const serviceGroups = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getServiceGroupsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: el.name
      }));
    },
    name: "Название",
    userRole: []
  }
];

const serviceGroupObjects = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getObjectsClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.name}, ${el.address}`
      }));
    },
    name: "Адрес, название",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "type",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ];
    },
    name: "Тип объекта",
    userRole: []
  }
];
const parents = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getUsersClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => {
        return {
          value: el.id,
          text: (el.last_name || " ") + (el.first_name || " ")
        };
      });
    },
    name: "ФИО",
    userRole: []
  }
];

const adminsOppen = [];

const firmwares = [
  {
    inputType: inputType.search,
    type: "chip",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: "NRF",
          text: "NRF"
        },
        {
          value: "ESP",
          text: "ESP"
        }
      ];
    },
    name: "Чип",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "board_version",
    function: async (param, allQuery = {}) => {
      return (
        await getControllerFirmwaresRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.board_version != null ? el.board_version : "null",
        text: el.board_version != null ? el.board_version : "-"
      }));
    },
    name: "Версия чипа",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_test",
    function: async () => {
      return [
        {
          value: "true",
          text: "Да"
        },
        {
          value: "false",
          text: "Нет"
        }
      ];
    },
    name: "Тестовая",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "is_active",
    function: async () => {
      return [
        {
          value: "false",
          text: "Не активен"
        },
        {
          value: "true",
          text: "Активен"
        }
      ];
    },
    name: "Статус",
    userRole: []
  }
];
const infopanel = [
  {
    inputType: inputType.search,
    type: "year",
    function: async () => {
      return [
        {
          value: "2024",
          text: "2024"
        },
        {
          value: "2023",
          text: "2023"
        },
        {
          value: "2022",
          text: "2022"
        },
        {
          value: "2021",
          text: "2021"
        },
        {
          value: "2020",
          text: "2020"
        },
        {
          value: "2019",
          text: "2019"
        }
      ];
    },
    name: "Год",
    userRole: [60]
  },

  {
    inputType: inputType.search,
    type: "month",
    function: async () => {
      return [
        {
          value: "1",
          text: "Январь"
        },
        {
          value: "2",
          text: "Февраль"
        },
        {
          value: "3",
          text: "Март"
        },
        {
          value: "4",
          text: "Апрель"
        },
        {
          value: "5",
          text: "Май"
        },
        {
          value: "6",
          text: "Июнь"
        },
        {
          value: "7",
          text: "Июль"
        },
        {
          value: "8",
          text: "Август"
        },
        {
          value: "9",
          text: "Сентябрь"
        },
        {
          value: "10",
          text: "Октябрь"
        },
        {
          value: "11",
          text: "Ноябрь"
        },
        {
          value: "12",
          text: "Декабрь"
        }
      ];
    },
    name: "Месяц",
    userRole: [60]
  }
];
const promocode = [
  {
    inputType: inputType.search,
    type: "search",
    function: async (param, allQuery = {}) => {
      return (
        await getPromocodeClearRequest({
          query: { ...allQuery, search: param, offset: null }
        })
      ).data.results.map(el => ({
        value: el.id,
        text: `${el.code} `
      }));
    },
    name: "Код промокода",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "by_city",
    function: async param => {
      return (
        await getCitiesTypesClearRequest({
          search: param,
          offset: null,
          type: "coupon"
        })
      ).data.results.map(el => ({ value: el.id, text: el.name }));
    },
    name: "Город",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "status",
    // eslint-disable-next-line no-unused-vars
    function: async () => {
      return [
        {
          value: "deactivated",
          text: "Деактивирован"
        },
        {
          value: "used",
          text: "Использован"
        },
        {
          value: "expired",
          text: " Просрочен"
        },
        {
          value: "created",
          text: "Создан"
        }
      ];
    },
    name: "Статус",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "by_object_type",
    function: async () => {
      return [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Детский сад", value: "kindergarten" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ];
    },
    name: "Тип объекта",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "by_access_type",
    function: async () => {
      return [
        { text: "Приложение", value: "app" },
        { text: "Карта", value: "card" }
      ];
    },
    name: "Тип доступа",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "started-at",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата начала активации",
    userRole: []
  },
  {
    inputType: inputType.dataPicker,
    type: "finished-at",
    function: async param => {
      return [{ value: param, text: param }];
    },
    name: "Дата окончания активации",
    userRole: []
  },
  {
    inputType: inputType.search,
    type: "show-deactivated",
    function: async () => {
      return [
        {
          value: "true",
          text: "Показать"
        },
        {
          value: "false",
          text: "Не показывать"
        }
      ];
    },
    name: "Деактивированный промокоды",
    userRole: []
  }
];
export {
  diller,
  organizations,
  objects,
  kindergartens,
  kindergartensDealer,
  organizationsCompany,
  controllers,
  cards,
  users,
  accounts,
  groups,
  payments,
  paymentsObject,
  service,
  serviceTask,
  message,
  groupChildren,
  Children,
  passLog,
  passLogObject,
  staff,
  serviceGroups,
  serviceGroupObjects,
  parents,
  adminsOppen,
  firmwares,
  infopanel,
  promocode,
  codes,
  access,
  passLogCards,
  objectUsers,
  usersKindergarten,
  kindergatensPassLog,
  review
};
